import { nextTick } from 'vue'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { DAILY_KEY, CATE_KEY, TSP_KEY, ATTR_KEY, BRAND_KEY, PRICE_KEY } from 'public/src/pages/product_list_v2/utils/index.js'


const SLICE_PUSH_INIT_COUNT = 40 // 初始化水合后每次push商品数量
const SLICE_PUSH_COUNT = 5       // 筛选、翻页每次push商品数量

let requestId = null 
function cancelRequestAnimationFrame () {
  if (!requestId) return 
  if (typeof window !== 'undefined' && typeof window.cancelAnimationFrame !== 'undefined') {
    cancelAnimationFrame(requestId)
  } else {
    clearTimeout(requestId)
  }
}


function safeRequestAnimationFrame(callback) {
  if (typeof window !== 'undefined' && typeof window.requestAnimationFrame !== 'undefined') {
    return window.requestAnimationFrame(callback)
  } else {
    // 如果在服务器端环境，使用一个兼容的降级方案
    return setTimeout(callback, 16)
  }
}

/**
 * @description 分割push商品数据
 * @param {Array} goods - 商品数据
 * @param {Number} splitNum - 分割数量
 * @param {Function} callback - 回调函数
 * */ 
const handleSplitGoods = (goods, splitNum, callback = () => {}, endCallBack = () => {}) => {
  if (!goods?.length) return
  cancelRequestAnimationFrame()

  const handleGoods = (arr, num) => {
    if (!arr?.length) {
      requestId = safeRequestAnimationFrame(async () => {
        await nextTick()

        const daEventExpose = window?.daEventCenter?.getExposeInstance()
        if (daEventExpose) {
          setTimeout(() => {
            daEventExpose.update('productListExposeCode')
          }, 2000)
        }
        
        endCallBack?.()
      })


      return
    }
    const goodsChunk = arr.slice(0, num)
    requestId = safeRequestAnimationFrame(() => {
      callback(goodsChunk)
      handleGoods(arr.slice(num), num)
    })
  }
  const initArr = goods.slice(0, splitNum)
  callback(initArr)
  goods = goods.slice(splitNum)
  handleGoods(goods, splitNum)
}

const mutations = {
  jumpUrl(state, { localUrl = '', queryObj = {}, isClickAttr = false }) {
    const url = localUrl ? localUrl : location.origin + location.pathname
    if (!isClickAttr) {
      delete queryObj.exc_attr_id
    }
    if (state?.results?.searchKeywords?.double_lang_correct) {
      queryObj.force_suggest = 1
    }
    const queryString = Object.keys(queryObj)
      .reduce((str, query) => {
        const value = queryObj[query]
        const needRemainZeroValue = ['min_price', 'max_price', 'sourceStatus'].includes(query) && Number(value) === 0
        if (value || needRemainZeroValue) {
          // attr_values前置
          if (query == 'attr_values') {
            str = str.replace(
              '?',
              `?${query}=${encodeURIComponent(queryObj[query])}&`
            )
          } else {
            str += `${query}=${encodeURIComponent(queryObj[query])}&`
          }
        }
        return str
      }, '?')
      .slice(0, -1)
    
    // 筛选后，滚动复位逻辑
    if (state.isSticky) {
      const scrollFixSubHeight = Array.from(document.querySelectorAll('.scrollfix-sub')).reduce(
        (curr, next) => (curr += next.offsetHeight),
        0
      )
        
      window.scrollTo(0, scrollFixSubHeight)
    }
    // 趋势词落地页不需要保留筛选状态, 无需更新链接参数
    if (state?.ssrPageType === 'trend_landing') return
    history.replaceState(null, '', url + queryString)
  },
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  initResults(state, payload) {
    state.results = payload
  },
  resetState(state, { payload }) {
    if (payload.language) state.Language = payload.language
  },
  updateSpecificResultAttr(state, { key, value }) {
    state.results[key] = value
  },
  dealingResults(state, payload) {
    const results = payload?.results || {}
    const { cat_info } = results
    const requestType = cat_info.requestType
    const oldFilterPrice = state.results.filterPrices

    if (requestType == 'refresh') {
      Object.keys(results).forEach(key => {
        state.results[key] = results[key]
      })
    } else if (requestType == 'pageChange') {
      state.results.cat_info = cat_info || {}
    }

    // 价格筛选条件下,不更新价格范围
    if (cat_info.min_price && cat_info.max_price) { 
      state.results.filterPrices = oldFilterPrice
    }
  },
  dealingFilterAttr (state, payload) { 
    const { Results } = state
    const { staticFilterId = '', staticFilterType, filterAttrs: oldFilterAttrs = [], filterTsps: oldFilterTsps = [] } = Results || {}
    const { cat_info = {}, filterAttrs: newFilterAttrs = [], filterTsps: newFilterTsps = [] } = payload?.results || {}
    const requestType = cat_info.requestType
    if (requestType == 'pageChange') return

    if (!newFilterAttrs?.length || !staticFilterId) {
      Results.filterAttrs = newFilterAttrs
      Results.filterTsps = newFilterTsps
      return
    }
    
    if (staticFilterType == 'attr') {
      const oldIndex = oldFilterAttrs.findIndex(attr => attr.attr_id == staticFilterId)
      const newIndex = newFilterAttrs.findIndex(attr => attr.attr_id == staticFilterId);
      (oldIndex > -1 && newIndex > -1) && (newFilterAttrs[newIndex] = oldFilterAttrs[oldIndex])
    } else if (staticFilterType == 'tsp') {
      const oldIndex = oldFilterTsps.findIndex(tsp => tsp.tagGroupId == staticFilterId)
      const newIndex = newFilterTsps.findIndex(tsp => tsp.tagGroupId == staticFilterId);
      (oldIndex > -1 && newIndex > -1) && (newFilterTsps[newIndex] = oldFilterTsps[oldIndex])
    }
    Results.filterAttrs = newFilterAttrs
    Results.filterTsps = newFilterTsps
  },
  /**
   * @description 商品列表数据处理
   * */ 
  dealingGoods({ Results, Language, listAbtResult }, payload) {
    let { goods = [], promotionInfoFromServer = {}, cat_info } = payload?.results || {}

    // 融合直接赋值，不需要预处理促销部分
    const isBffGoods = goods?.length && goods[0]?.bffGoodsInfo
    if (!isBffGoods) {
      dealProductsPretreatInfo({
        products: goods, 
        promotionInfoFromServer, 
        language: Language,
      })
    }

    if (cat_info.isPageLoadMore) { // 小分页逻辑，push商品
      Results.goods.push(...goods)
      return
    }
    const isSliceRender = typeof window !== 'undefined' && listAbtResult?.ProductListStrategyRender?.p?.open === 'yes' // 是否开启分片渲染
    const requestType = cat_info.requestType
    if (isSliceRender && ['refresh', 'pageChange'].includes(requestType)) { // 筛选和翻页进行拆分时间处理
      Results.goods = []
      handleSplitGoods(goods, SLICE_PUSH_COUNT, (arr) => {
        Results.goods.push(...arr)
      }, payload.endCallBack)
    } else {
      Results.goods = goods
    }
  },

  dealingSplitGoods({ Results, Language }, payload) {
    const { goods, promotionInfoFromServer = {}, slicePushCount = SLICE_PUSH_INIT_COUNT, endCallBack } = payload

    let list = goods

    // 融合直接赋值，不需要预处理促销部分
    const isBffGoods = goods?.length && goods[0]?.bffGoodsInfo
    if (!isBffGoods) {
      dealProductsPretreatInfo({
        products: goods, 
        promotionInfoFromServer, 
        language: Language,
      })
    }

    handleSplitGoods(list, slicePushCount, (arr) => {
      Results.goods.push(...arr)
    }, endCallBack)
  },
  /**
 * @description 处理选中的筛选词
 * selectedFilter: 最终展示在页面的已选筛选项
 * selectedDataList: 数据返回的已选筛选项
 * selectedInteractionList: 用户交互选中/删除的筛选项
 * selectedFilter = selectedDataList + selectedInteractionList
 * item: 当前操作筛选项,如在filterResults中删除某个筛选项
 */
  dealingSelectedFilter (state, payload) { 
    const requestType = payload?.results?.cat_info?.requestType || ''
    if (requestType == 'pageChange') return

    const isFirstLoad = requestType == 'firstLoad'
    const { Results, lang, currency, currencies } = state
    const { cat_info = {}, dailyDates, selectedCate, selectedTsps, selectedAttrs, shouldUseCccxFilterChain } = payload?.results || {}

    const { daily, min_price: curMin, max_price: curMax } = cat_info
    const selectedDataList = []
    const selectedInteractionList = isFirstLoad ? [] : Results.selectedInteractionList //  firstLoad时，清空选中的交互筛选项

    // 选中的日期
    daily && selectedDataList.push({
      key: DAILY_KEY,
      label_id: daily,
      label: dailyDates?.[daily]
    })
    
    // 选中的分类
    selectedCate?.forEach(cate => {
      selectedDataList.push({
        key: CATE_KEY,
        label_id: cate.cat_id,
        label: cate.cat_name
      })
    })
    
    // 选中的tsp筛选
    selectedTsps?.forEach(tsp => {
      selectedDataList.push({
        key: TSP_KEY,
        label_id: tsp.tagId,
        label: tsp.tagValue
      })
    })
    
    // 选中的属性 - 3
    selectedAttrs?.forEach(attr => {
      let key = ATTR_KEY
      let label_id = attr.attr_filter

      if (attr.nodeType == 9) {
        key = BRAND_KEY
        label_id = attr.nodeId
      } else if (attr.nodeType == 7) {
        key = TSP_KEY
        label_id = attr.attr_id + '_' + attr.attr_filter
      } else if (shouldUseCccxFilterChain) {
        label_id = attr.nodeId
      }

      selectedDataList.push({
        key,
        label_id,
        label: attr.attr_value || attr.group_name,
        img: attr.group_image || attr.attr_image,
      })
    })
    
    // 价格区间 - 4
    const hasPriceRange = curMin && curMax
    if (hasPriceRange) {
      const { symbol_left, symbol_right } = currencies?.[lang]?.[currency] || {}
      const minPrice = symbol_left + curMin + symbol_right
      const maxPrice = symbol_left + curMax + symbol_right
      selectedDataList.push({
        key: PRICE_KEY,
        label_id: PRICE_KEY,
        label: minPrice + '-' + maxPrice,
      })
    }

    const selectedFilter = []
    const dataIds = selectedDataList.map(item => item.label_id)
    const interactionIds = selectedInteractionList.map(item => item.label_id)
    selectedInteractionList.forEach(item => {
      if (!dataIds.includes(item.label_id)) return
      if (selectedFilter.find(obj => obj.key == item.key && obj.label_id == item.label_id)) return

      selectedFilter.push(item)
    })
    selectedDataList.forEach(item => {
      if (interactionIds.includes(item.label_id)) return
      if (selectedFilter.find(obj => obj.key == item.key && obj.label_id == item.label_id)) return

      selectedFilter.push(item)
    })
    
    Results.selectedFilter = selectedFilter
    Results.selectedInteractionList = selectedInteractionList

  },
  
  updateSelectedFilter ({ Results }, item) { 
    const { key, label_id } = item

    if (key == DAILY_KEY) { 
      Results.selectedInteractionList = []
      Results.selectedFilter = []
    } else if ([CATE_KEY, PRICE_KEY].includes(key)) {
      Results.selectedInteractionList = Results.selectedInteractionList.filter(obj => obj.key != key)
      Results.selectedFilter = Results.selectedFilter.filter(obj => obj.key != key)
    } else {
      Results.selectedInteractionList = Results.selectedInteractionList.filter(obj => obj.key != key || obj.label_id != label_id)
      Results.selectedFilter = Results.selectedFilter.filter(obj => obj.key != key  || obj.label_id != label_id)
    }

    if (!item.isDelete) {
      Results.selectedInteractionList.unshift(item)
      Results.selectedFilter.unshift(item)
    }

  },

  deleteSelectedFilter ({ Results }, item) {
    const { key } = item
    if ([CATE_KEY, PRICE_KEY].includes(key)) {
      Results.selectedFilter = Results.selectedFilter.filter(obj => obj.key != key)
      Results.selectedInteractionList = Results.selectedInteractionList.filter(obj => obj.key != key)
    } else {
      Results.selectedFilter = Results.selectedFilter.filter(obj => obj.type != item.type || obj.label_id != item.label_id)
      Results.selectedInteractionList = Results.selectedInteractionList.filter(obj => obj.type != item.type || obj.label_id != item.label_id)
    }
  },
  clearAllSelectedFilter ({ Results }) {
    Results.selectedInteractionList = []
    Results.selectedFilter = []
  }
}

export default mutations
