const PAGE_NAME_MAP = { page_goods_group: 'shein_picks', page_daily_new: 'daily_new', } // 页面标识映射

// 混入原子接口内容
const assignAtomicInfo = ({ goods, atomicInfo, cb }) => {
  goods.forEach(item => {
    const goodsIdMapAtomicInfo = atomicInfo[item.goods_id] || {}
    Object.keys(goodsIdMapAtomicInfo).forEach(key => {
      // goods_id不需要混入，多数情况goods_id作为循环的key，但不同接口返回的goods_id数据类型不同，会导致列表闪烁
      if (key !== 'goods_id') item[key] = goodsIdMapAtomicInfo[key]
    })
    
    // 混入结束后的回调
    cb && typeof cb === 'function' && cb(item)
  })

  return goods
}

// 入参错误校验
const checkParams = ({ goods, item, fields, pageKey, sceneKey }) => {
  let errLog = ''
  let { locateLabels, enableDecisionAttr, cccTspBadges, skuPrices, promoLabel, userBehaviorLabel, beltLabel, rankingList } = fields

  if (item) {
    if (!item.goods_id) {
      errLog += '商品缺少goods_id；'
    }
    
    // if (skuPrices && !item.sku_code) {
    //   errLog += `goods_id: ${item.goods_id}，缺少sku_code；`
    // }
    
    if ((enableDecisionAttr || cccTspBadges) && !item.cat_id) {
      errLog += `goods_id: ${item.goods_id}，缺少cat_id；`
    }
  } else {
    if (!goods?.length) {
      errLog += 'goods为空；'
    }
  
    if ((enableDecisionAttr || cccTspBadges) && (!pageKey || !sceneKey)) {
      if (!pageKey) {
        errLog += '; 缺少pageKey；'
      }
      if (!sceneKey) {
        errLog += '; 缺少sceneKey；'
      }
    }
    if ((promoLabel || userBehaviorLabel || beltLabel || rankingList || locateLabels) && !pageKey) {
      errLog += '; 缺少pageKey'
    }
  }

  if (errLog) console.error(errLog)

  return errLog
}

// 处理入参
const handleParams = ({ goods, fields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid, mallInfo, abtInfo, pricesWithPromotionSpecialSceneType }) => {
  const { locateLabels, enableDecisionAttr, cccTspBadges, skuPrices, catName, quickShip, promoLabel, userBehaviorLabel, beltLabel, rankingList, starComment } = fields
  checkParams({ goods, fields, pageKey, sceneKey })
  const atomicParams = []
  const goodsIdObj = {}
  for(let i = 0; i < goods.length; i++) {
    const { goods_id, goods_sn, cat_id, sku_code, mall_code, promotionId, productRelationID, goods_relation_id, store_code } = goods[i]
    const spuId = productRelationID || goods_relation_id // productRelationID 主商品的spu字段，goods_relation_id 色块的spu字段

    // 去重
    if (!goodsIdObj[goods_id]) {

      goodsIdObj[goods_id] = 1

      checkParams({ item: goods[i], fields })

      const params = {}
      if (mall_code) Object.assign(params, { mall_code })
      if (goods_id) Object.assign(params, { goods_id })
      if (goods_sn) Object.assign(params, { goods_sn })
      if (promotionId) Object.assign(params, { promotionId })
      if (cat_id && (enableDecisionAttr || cccTspBadges || catName || rankingList)) Object.assign(params, { cat_id })
      if (sku_code && (skuPrices || quickShip)) Object.assign(params, { sku_code })
      if (spuId && starComment) Object.assign(params, { spuId })
      if (store_code) Object.assign(params, { store_code })

      atomicParams.push(params)
    }
  }

  const reqParams = { atomicParams, fields, caller, isPaid, mallInfo, abtInfo, pricesWithPromotionSpecialSceneType }
  if (locateLabels || enableDecisionAttr || cccTspBadges || promoLabel || userBehaviorLabel || beltLabel || rankingList) {
    reqParams.scene = {
      sceneKey: cccTspBadges ? sceneKey : undefined,
      pageKey: PAGE_NAME_MAP[pageKey] || pageKey,
      subPageKey
    }
  }
  if (Object.keys(cccParams || {}).length) {
    Object.assign(reqParams, { cccParams })
  }

  return { reqParams }
}

// 混入需要的值，实现数据动态响应
const setPretreatInfo = ({ goodsItem = null, field = null, value = null } = {}) => {
  if (!goodsItem || !field) return
  if (!goodsItem.pretreatInfo) goodsItem['pretreatInfo'] = {}
  goodsItem.pretreatInfo[field] = value
}

const includsPageKey = ({ pageKey }) => {
  return ['page_real_class', 'page_select_class', 'daily_new', 'shein_picks', 'page_coupon_product', 'page_real_time_feedback', 'page_search'].includes(PAGE_NAME_MAP[pageKey] || pageKey)
}

export {
  assignAtomicInfo,
  checkParams,
  handleParams,
  setPretreatInfo,
  includsPageKey
}
